



















import { Action } from "vuex-class";
import { Component, Vue, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    FormBase,
    FormInput,
    FormError,
    FormSubmit,
  },
})
export default class PageInviteAccept extends Vue {
  @Action("invite/read") readInvite!: (payload: { invite_code: string }) => Promise<PrefilledInvite>;

  @Action("auth/register") register!: (payload: RegisterPayload) => Promise<void>;

  @Ref() form!: FormClass;

  loading = false;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: RegisterPayload = {
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    locale: "",
    invite_code: "",
  };

  mounted() {
    this.loading = true;
    if (this.$route.query && this.$route.query.invite_code) {
      this.readInvite({ invite_code: `${this.$route.query.invite_code}` })
        .then((prefilledInvite: PrefilledInvite) => {
          this.payload.email = prefilledInvite.prefilled_data.email;
          this.payload.first_name = prefilledInvite.prefilled_data.first_name;
          this.payload.last_name = prefilledInvite.prefilled_data.last_name;
          this.payload.locale = prefilledInvite.locale;
          this.payload.invite_code = `${this.$route.query.invite_code}`;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
      this.errorResponse = {
        status: 404,
        errors: [{ message: "No invite code provided" }],
      };
    }
  }

  private submit() {
    this.register(this.payload)
      .then(() => this.$router.push({ name: "login", query: { email: this.payload.email } }))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }
}
